import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  },
  {
    path: 'compartir',
    loadChildren: () => import('../../compartir/compartir.module').then(m => m.CompartirModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('../../configuration/configuration.module').then(m => m.ConfigurationModule)
  },
  {
    path: 'products',
    loadChildren: () => import('../../products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'stores',
    loadChildren: () => import('../../stores/stores.module').then(m => m.StoresModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('../../order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'reservations',
    loadChildren: () => import('../../reservation-order/reservation-order.module').then(m => m.ReservationOrderModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('../../customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('../../categories/categories.module').then(m => m.CategoryModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('../../promotions/promotions.module').then(m => m.PromotionsModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'kit',
    loadChildren: () => import('../../reservations/reservation.module').then(m => m.ReservationModule)
  },
  {
    path: 'social-commerce',
    loadChildren: () => import('../../social-commerce/social-commerce').then(m => m.SocialCommerceModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../support/support.module').then(m => m.SupportModule)
  }
  ,
  {
    path: 'clients',
    loadChildren: () => import('../../clients/clients.module').then(m => m.ClientsModule)
  }



];
