import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavigationService } from './../../navigation/navigation.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private navigationService : NavigationService
  ) {

  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.navigationService.navigate('pages/login');
      return false;
    }
    return true;
  }

}
