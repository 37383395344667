<p style="color: #a169ff; font-weight: bold; font-size: 24px;"> {{'configuration.seleccionaBanners' | translate}} </p>
<div style="width: 100%; height: 1px; background-color: lightslategray;"></div>
<br>

<input
  type="file"
  style="margin-bottom: 20px;"
  multiple
  (change)="fileChangeEvent($event)"
>
<br>
<p style="font-weight: 600; font-size: 20px; color: #de718f;"> {{'files.archivos' | translate}} :</p>

<div class="row">
  <div class="col-lg-4 col-sm-12 text-break text-wrap listFiles">
    <ul class="listFiles">
      <li *ngFor="let item of listaBanners1">
        {{item.name}}
      </li>
    </ul>
  </div>

  <div class="col-lg-8 col-sm-12">
    <ngb-carousel
      *ngIf="slides1"
      style="max-width: 900px; max-height: 300px; width: 100%; height: 300px; background-color: #1E1E1E;"
    >
      <ng-container *ngFor="let slide of slides1">
        <ng-template ngbSlide>
          <div style="display: flex; justify-content: center;">
            <img [src]="slide.img.url" alt="Banner" style="max-height: 300px;">
          </div>
          <div class="carousel-caption" style="background-color: rgba(0, 0, 0, 0.8); border: 1px solid white;">
            <h3 style="color: #F3849D">{{slide.description.title}}</h3>
            <p>
              {{slide.description.detail}}<br>
              {{slide.img.title}}<br>
              <a href="{{slide.urlButton}}" target="_blank" style="text-decoration: underline;">{{slide.urlButton}}</a>
            </p>
          </div>
        </ng-template>
      </ng-container>

    </ngb-carousel>
  </div>
</div>

<div class="overflow-x-scroll mt-3">
  <div class="list-group flex-row row ml-3" style="width: max-content;">
    <div class="card list-group-item mr-3 shadow-big" *ngFor="let item of images1; let idx = index;"
         style="width: auto; border: 1px solid #F3849D;">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title font-weight-bold" style="color: #de718f;"> {{'dashboardHomeBack.datosBanner' | translate}}  {{idx}}</h5>
        <br>
        <div>
          <label class="d-flex align-items-center justify-content-center">
            <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.tituloBanner' | translate}}:</span>
            <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                   (change)="onInput1Change(1, $event, idx)">
          </label>
        </div>
        <br>
        <div>
          <label class="d-flex align-items-center justify-content-center">
            <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.descripcionBanner' | translate}}:</span>
            <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                   (change)="onInput1Change(2, $event, idx)">
          </label>
        </div>
        <br>
        <div>
          <label class="d-flex align-items-center justify-content-center">
            <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;"> {{'dashboardHomeBack.txtBoton' | translate}} :</span>
            <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                   (change)="onInput1Change(3, $event, idx)">
          </label>
        </div>
        <br>
        <div>
          <label class="d-flex align-items-center justify-content-center">
            <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.linkProducto' | translate}}:</span>
            <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                   (change)="onInput1Change(5, $event, idx)">
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  class="btn btn-primary mt-3 mb-3 text-white btn-block"
  *ngIf="images1.length > 0"
  (click)="sendBanner()"
>
  {{'dashboardHomeBack.actBanners' | translate}}
</button>

<!-- **************************** SECTIONS *************************** -->
<p class="mt-5" style="color: #a169ff; font-weight: bold; font-size: 24px;">  {{'dashboardHomeBack.contenidoSegundaSeccion' | translate}}.</p>
<div class="d-flex justify-content-start align-items-center">
  <div class="mr-3">
    <button class="btn btn-primary mb-3 text-white btn-block" (click)="addSection(1)">{{'dashboardHomeBack.AgregarSeccionBanner' | translate}}</button>
  </div>
  <div>
    <button class="btn btn-primary mb-3 text-white btn-block" (click)="addSection(2)">{{'dashboardHomeBack.AgregarSeccionImg' | translate}}</button>
  </div>
</div>
<div style="width: 100%; height: 1px; background-color: lightslategray;"></div>
<br>

<div *ngFor="let section of sectionsList; let idx = index;" class="mb-3">

  <div *ngIf="section.typeSection === 1" class="mt-4 mb-4 border card shadow-big p-2">

    <div class="w-100 d-flex align-items-center mb-1">
      <p class="w-100 text-center" style="font-weight: 600; font-size: 24px; color: #de718f;">
        {{'dashboardHomeBack.seccion' | translate}} --{{section.title}}--</p>
    </div>

    <label class="d-flex align-items-center justify-content-center w-100 mb-4">
      <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;"> {{'dashboardHomeBack.tituloSeccion' | translate}}:</span>
      <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
             (change)="onSectionTitleChange($event, idx)">
    </label>

    <input
      type="file"
      style="margin-bottom: 20px;"
      multiple
      (change)="fileChangeEventSections($event, idx)"
    >
    <br>
    <p style="font-weight: 600; font-size: 20px; color: #de718f;"> {{'files.archivos' | translate}}:</p>

    <div class="row">
      <div class="col-lg-4 col-sm-12 text-break text-wrap listFiles">
        <ul class="listFiles">
          <li *ngFor="let item of sectionsList[idx].listFiles">
            {{item.name}}
          </li>
        </ul>
      </div>

      <div class="col-lg-8 col-sm-12">
        <ngb-carousel
          *ngIf="sectionsList[idx].banners"
          style="max-width: 900px; max-height: 300px; width: 100%; height: 300px; background-color: #1E1E1E;"
        >
          <ng-container *ngFor="let slide of sectionsList[idx].banners">
            <ng-template ngbSlide>
              <div style="display: flex; justify-content: center;">
                <img [src]="slide.img.url" alt="Banner" style="max-height: 300px;">
              </div>
              <div class="carousel-caption" style="background-color: rgba(0, 0, 0, 0.8); border: 1px solid white;">
                <h3 style="color: #F3849D">{{slide.description.title}}</h3>
                <p>
                  {{slide.description.detail}}<br>
                  {{slide.img.title}}
                </p>
              </div>
            </ng-template>
          </ng-container>

        </ngb-carousel>
      </div>
    </div>

    <div class="overflow-x-scroll mt-3">
      <div class="list-group flex-row row ml-3" style="width: max-content;">
        <div class="card list-group-item mr-3 shadow-big"
             *ngFor="let item of sectionsList[idx].listImagesCodec; let idxData = index;"
             style="width: auto; border: 1px solid #F3849D;">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title font-weight-bold" style="color: #de718f;">{{'dashboardHomeBack.datosBanner' | translate}} {{idxData}}</h5>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.tituloBanner' | translate}}:</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(1, $event, idx, idxData)">
              </label>
            </div>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.descripcionBanner' | translate}}:</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(2, $event, idx, idxData)">
              </label>
            </div>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.txtBoton' | translate}}:</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(3, $event, idx, idxData)">
              </label>
            </div>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.linkProducto' | translate}}:</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(5, $event, idx, idxData)">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!-- End Banner Section -->

  <div *ngIf="section.typeSection === 2" class="mt-4 mb-4 border card shadow-big p-2">

    <div class="w-100 d-flex align-items-center mb-1">
      <p class="w-100 text-center" style="font-weight: 600; font-size: 24px; color: #de718f;">
        {{'dashboardHomeBack.seccion' | translate}} --{{section.title}}--</p>
    </div>

    <label class="d-flex align-items-center justify-content-center w-100 mb-4">
      <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.tituloSeccion' | translate}} :</span>
      <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
             (change)="onSectionTitleChange($event, idx)">
    </label>

    <div>
      <label class="d-flex align-items-center justify-content-center w-100 mb-4">
        <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.fondoSeccion' | translate}} :</span>
        <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
               (change)="onSectionBackgroundChange($event, idx)">
      </label>
    </div>

    <input
      type="file"
      style="margin-bottom: 20px;"
      multiple
      (change)="fileChangeEventSections($event, idx)"
    >
    <br>
    <p style="font-weight: 600; font-size: 20px; color: #de718f;">{{'files.archivos' | translate}} :</p>

    <div class="row">
      <div class="col-lg-4 col-sm-12 text-break text-wrap listFiles">
        <ul class="listFiles">
          <li *ngFor="let item of sectionsList[idx].listFiles">
            {{item.name}}
          </li>
        </ul>
      </div>

      <div class="col-lg-8 col-sm-12">
        <ngb-carousel
          *ngIf="sectionsList[idx].banners"
          style="max-width: 900px; max-height: 300px; width: 100%; height: 300px; background-color: #1E1E1E;"
        >
          <ng-container *ngFor="let slide of sectionsList[idx].banners">
            <ng-template ngbSlide>
              <div style="display: flex; justify-content: center;">
                <img [src]="slide.img.url" alt="Banner" style="max-height: 300px;">
              </div>
              <div class="carousel-caption" style="background-color: rgba(0, 0, 0, 0.8); border: 1px solid white;">
                <h3 style="color: #F3849D">$ {{slide.price}}</h3>
                <p>
                  {{slide.description.detail}}<br>
                  <a href="{{slide.urlButton}}" target="_blank" style="text-decoration: underline;">{{slide.urlButton}}</a>
                </p>
              </div>
            </ng-template>
          </ng-container>

        </ngb-carousel>
      </div>
    </div>

    <div class="overflow-x-scroll mt-3">
      <div class="list-group flex-row row ml-3" style="width: max-content;">
        <div class="card list-group-item mr-3 shadow-big"
             *ngFor="let item of sectionsList[idx].listImagesCodec; let idxData = index;"
             style="width: auto; border: 1px solid #F3849D;">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title font-weight-bold" style="color: #de718f;"> {{'dashboardHomeBack.datosBanner' | translate}} {{idxData}}</h5>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.PrecioProducto' | translate}} :</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(4, $event, idx, idxData)">
              </label>
            </div>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.descripcionBanner' | translate}}:</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(2, $event, idx, idxData)">
              </label>
            </div>
            <br>
            <div>
              <label class="d-flex align-items-center justify-content-center">
                <span class="mr-1 flex-grow-0" style="color: #F3849D; font-weight: 600;">{{'dashboardHomeBack.linkProducto' | translate}}:</span>
                <input type="text" class="flex-grow-1 rounded" style="border: 1px solid #F3849D;"
                       (change)="onInput1ChangeSections(5, $event, idx, idxData)">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div><!-- End Image Section -->

</div>

<button
  class="btn btn-primary mt-3 mb-5 text-white btn-block"
  *ngIf="sectionsList.length > 0"
  (click)="sendSections()"
>
  {{'dashboardHomeBack.actSecciones' | translate}}
</button>
