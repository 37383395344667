import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router: Router
  ) { 

  }

  navigate(option: any, params? : any) {
    if(params) {
      this.router.navigate([option, {params : JSON.stringify(params)}]);
    }
    else {
      this.router.navigate([option]);
    }
  }
}
