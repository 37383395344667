import {Component, OnInit} from '@angular/core';
import {BannerS, ImageSectionClass, SectionHomeBannersClass, SectionHomeImagesClass} from './homeBackTypes';

@Component({
  selector: 'app-home-back',
  templateUrl: './home-back.component.html',
  styleUrls: ['./home-back.component.scss']
})

export class HomeBackComponent implements OnInit {

  images1 = [];
  listaBanners1 = [];
  slides1: BannerS[];
  slides1TMP: Array<BannerS>;
  // ****************** SECTIONS *******************
  sectionsList: (SectionHomeImagesClass | SectionHomeBannersClass)[];

  constructor() {
  }

  // Save input files into Images Arrays
  public fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.listaBanners1 = fileInput.target.files;
      this.images1 = [];
      this.slides1TMP = [];

      for (let i = 0; i < this.listaBanners1.length; i++) {
        const reader = new FileReader();
        // this.imagePath = files;
        reader.readAsDataURL(this.listaBanners1[i]);
        reader.onload = (_event) => {
          if (typeof reader.result === 'string') {
            this.images1.push(reader.result);
          }
        }
      }

      for (let i = 0; i < this.listaBanners1.length; i++) {
        this.slides1TMP.push(new BannerS());
      }

      // console.log('Images LENGTH: ' + this.listaBanners1.length);
      // console.log('Banners LENGTH: ' + this.slides1TMP.length);

    }
  }

  // Save text data into slides array
  public onInput1Change(inputNumber: number, textData: any, index: number) {
    // console.log(textData.target.value);

    if (this.slides1TMP[index] === null || this.slides1TMP[index] === undefined) {
      this.slides1TMP.push(new BannerS());
    }

    if (this.slides1TMP[index].img.url === '') {
      for (let j = 0; j < this.listaBanners1.length; j++) {
        this.slides1TMP[j].img.url = this.images1[j];
      }
    }

    if (inputNumber === 1) {
      this.slides1TMP[index].description.title = textData.target.value;
    }

    if (inputNumber === 2) {
      this.slides1TMP[index].description.detail = textData.target.value;
    }

    if (inputNumber === 3) {
      this.slides1TMP[index].img.title = textData.target.value;
    }

    if (inputNumber === 5) {
      this.slides1TMP[index].urlButton = textData.target.value;
    }

    this.slides1 = [];
    this.slides1 = this.slides1TMP;
    // console.log(JSON.stringify(this.slides1TMP));
  }

  // ********************************* SECTIONS *************************************

  // 1= Banner & 2= Images
  public addSection(typeSection: number) {
    if (typeSection === 1) {
      this.sectionsList.push(new SectionHomeBannersClass());
    } else {
      this.sectionsList.push(new SectionHomeImagesClass());
    }
    // console.log(this.sectionsList);
  }

  public onSectionTitleChange(eventParam: any, idx: number) {
    this.sectionsList[idx].title = eventParam.target.value;
  }

  public onSectionBackgroundChange(eventParam: any, idx: number) {

    if (eventParam.target.value.length > 10) {
      this.sectionsList[idx].urlBackgroundImage = eventParam.target.value;
      this.sectionsList[idx].sectionHasBackground = true;
    } else {
      this.sectionsList[idx].urlBackgroundImage = eventParam.target.value;
      this.sectionsList[idx].sectionHasBackground = false;
    }

  }

  public fileChangeEventSections(fileInput: any, idx: number) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // console.log(fileInput.target.files);

      this.sectionsList[idx].listFiles = [];
      this.sectionsList[idx].listFiles = fileInput.target.files;
      // this.listaBanners1 = fileInput.target.files;

      this.sectionsList[idx].listImagesCodec = [];
      this.sectionsList[idx].bannersTMP = [];
      this.sectionsList[idx].bannersTMP = [];
      // this.images1 = [];
      // this.slides1TMP = [];

      for (let i = 0; i < this.sectionsList[idx].listFiles.length; i++) {
        const reader = new FileReader();
        // this.imagePath = files;
        reader.readAsDataURL(this.sectionsList[idx].listFiles[i]);
        reader.onload = (_event) => {
          if (typeof reader.result === 'string') {
            this.sectionsList[idx].listImagesCodec.push(reader.result);
          }
        }
      }

      if (this.sectionsList[idx].typeSection === 1) {
        for (let i = 0; i < this.sectionsList[idx].listFiles.length; i++) {
          this.sectionsList[idx].bannersTMP.push(new BannerS());
        }
      }

      if (this.sectionsList[idx].typeSection === 2) {
        for (let i = 0; i < this.sectionsList[idx].listFiles.length; i++) {
          this.sectionsList[idx].bannersTMP.push(new BannerS());
        }
      }

      // console.log('Images Length: ' + this.sectionsList[idx].bannersTMP.length);

    }
  }

  public onInput1ChangeSections(inputNumber: number, textData: any, idxSection: number, idxArray: number) {

    if (this.sectionsList[idxSection].bannersTMP[idxArray].img.url === '') {
      for (let j = 0; j < this.sectionsList[idxSection].listImagesCodec.length; j++) {
        this.sectionsList[idxSection].bannersTMP[j].img.url = this.sectionsList[idxSection].listImagesCodec[j];
      }
    }

    if (inputNumber === 1) {
      // this.slides1TMP[index].description.title = textData.target.value;
      this.sectionsList[idxSection].bannersTMP[idxArray].description.title = textData.target.value;
    }

    if (inputNumber === 2) {
      // this.slides1TMP[index].description.detail = textData.target.value;
      this.sectionsList[idxSection].bannersTMP[idxArray].description.detail = textData.target.value;
    }

    if (inputNumber === 3) {
      // this.slides1TMP[index].img.title = textData.target.value;
      this.sectionsList[idxSection].bannersTMP[idxArray].img.title = textData.target.value;
    }

    if (inputNumber === 4) {
      this.sectionsList[idxSection].bannersTMP[idxArray].price = textData.target.value;
    }

    if (inputNumber === 5) {
      this.sectionsList[idxSection].bannersTMP[idxArray].urlButton = textData.target.value;
    }

    this.sectionsList[idxSection].banners = [];
    this.sectionsList[idxSection].banners = this.sectionsList[idxSection].bannersTMP;

    // console.log(this.sectionsList[idxSection].bannersTMP);
    // console.log(this.sectionsList[idxSection].banners);

  }

  // ***************************** SEND INFO **************************
  public sendBanner() {
    console.log(JSON.stringify(this.slides1));
  }

  public sendSections() {
    console.log(JSON.stringify(this.sectionsList));
  }

  ngOnInit(): void {
    this.sectionsList = [];
    this.slides1TMP = [];
    this.slides1 = [];
  }

}
