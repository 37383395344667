import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brazilPipe'
})
export class BrazilPipe implements PipeTransform {

      transform(value: string,
        currencySign: string = '$ ',
        decimalLength: number = 2,
        chunkDelimiter: string = '.',
        decimalDelimiter:string = ',',
        chunkLength: number = 3): string {
        let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
        let num = parseFloat(value).toFixed(Math.max(0, ~~decimalLength));

        return currencySign+(decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
    }

}


