// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
 //API_URL: "https://apipartners.byflexy.com/api/v1/",
 //API_URL_IMAGE: "https://apipartners.byflexy.com/api/v1/admin/files/",
 //API_URL: "https://apicolombia.asesoraspandora.com/api/v1/",
// API_URL_IMAGE: "https://apicolombia.asesoraspandora.com/api/v1/admin/files/", 
 //CLIENT: 'pandoracolombia'

 
 API_URL: "https://apibolivia.asesoraspandora.com/api/v1/",
 API_URL_IMAGE: "https://apibolivia.asesoraspandora.com/api/v1/admin/files/",
 CLIENT: 'pandorabolivia'
 
/*
API_URL: "https://apimexico.asesoraspandora.com/api/v1/",
API_URL_IMAGE: "https://apimexico.asesoraspandora.com/api/v1/admin/files/",
CLIENT: 'pandoramexico'*/


};
