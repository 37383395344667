import {Ima} from 'ngx-videogular/lib/ima-ads/vg-ima-ads';

interface ImageBanner {
  url: string;
  title: string;
}

class ImageS implements ImageBanner {
  title: string;
  url: string;

  constructor() {
    this.title = '';
    this.url = '';
  }

}

interface DescriptionBanner {
  title: string;
  detail: string;
}

class DescriptionS implements DescriptionBanner {
  detail: string;
  title: string;

  constructor() {
    this.detail = '';
    this.title = '';
  }
}

interface BannerSlide {
  id: string;
  img: ImageS;
  description: DescriptionS;
  urlButton: string;
  price: string;
}

export class BannerS implements BannerSlide {
  description: DescriptionS;
  id: string;
  img: ImageS;
  urlButton: string;
  price: string;

  constructor() {
    this.description = new DescriptionS();
    this.id = '';
    this.img = new ImageS();
    this.urlButton = '';
    this.price = '';
  }
}

// ****************************************************************
interface ImageSection2 {
  url: string;
  description: string;
  price: string;
}

export class ImageSectionClass implements ImageSection2 {
  description: string;
  price: string;
  url: string;

  constructor() {
    this.description = '';
    this.price = '';
    this.url = '';
  }
}

// ************************************************
interface SectionHomeImages {
  title: string;
  banners: (BannerS)[];
  bannersTMP: (BannerS)[];
  listImagesCodec: Array<string>;
  listFiles: any[];
  typeSection: number;
  urlBackgroundImage: string;
  sectionHasBackground: boolean;
}

export class SectionHomeImagesClass implements SectionHomeImages {
  title: string;
  banners: (BannerS)[];
  bannersTMP: (BannerS)[];
  listImagesCodec: string[];
  listFiles: any[];
  typeSection: number;
  urlBackgroundImage: string;
  sectionHasBackground: boolean;

  constructor() {
    this.title = '';
    this.banners = [];
    this.bannersTMP = [];
    this.listImagesCodec = []
    this.listFiles = [];
    this.typeSection = 2;
    this.urlBackgroundImage = '';
    this.sectionHasBackground = false;
  }

}

interface SectionHomeBanners {
  title: string;
  banners: (BannerS)[];
  bannersTMP: (BannerS)[];
  listImagesCodec: Array<string>;
  listFiles: any[];
  typeSection: number;
  urlBackgroundImage: string;
  sectionHasBackground: boolean;
}

export class SectionHomeBannersClass implements SectionHomeBanners {
  banners: (BannerS)[];
  bannersTMP: (BannerS)[];
  listImagesCodec: Array<string>;
  listFiles: any[];
  title: string;
  typeSection: number;
  urlBackgroundImage: string;
  sectionHasBackground: boolean;

  constructor() {
    this.title = '';
    this.banners = [];
    this.bannersTMP = [];
    this.listImagesCodec = [];
    this.listFiles = [];
    this.typeSection = 1;
    this.urlBackgroundImage = '';
    this.sectionHasBackground = false;
  }
}

// ************************************************


