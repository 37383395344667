import { StorageService } from './../../storage/storage.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from './../../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpService : HttpService,
    private storageService : StorageService
  ) {

  }

  doLogin(data: any) : Promise<any> {
    return this.httpService.loginPostWithoutToken(`authenticate`, data);
  }

  doLogout() : void {
    this.storageService.clear();
  }

  isAuthenticated(): boolean {
    return this.storageService.isAuthenticated();
  }

  getMyRol() : Promise<any> {
    return this.httpService.post(`admin/users/getRole`);
  }

}
