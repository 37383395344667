import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  token:string = ''
  constructor(
    private http: HttpClient,
  ) {
    this.token = localStorage.getItem('token') || ''
  }

  get(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (params) {

        return this.http.get(`${environment.API_URL}${url}`, { params: params })
          .subscribe((res: any) => {
            if (res.state == 'success') {
              resolve({ response: res.response.data, msg: 'success' });
            }
            else {
              reject({ msg: res.msg });
            }
          }, (err): any => {
            reject({ msg: err.error.error });
          });
      } else {
        return this.http.get(`${environment.API_URL}${url}`)
          .subscribe((res: any) => {
            if (res.state == 'success') {
              resolve({ response: res.response.data, msg: 'success' });
            }
            else {
              reject({ msg: res.msg });
            }
          }, (err): any => {
            reject({ msg: err.error.error });
          });
      }

    });
  }

  getFile(url: string): any {
    return this.http.get(`${url}`);
  }

  post(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${environment.API_URL}${url}`, JSON.stringify(params))
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ data: response.data, msg: 'success' });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          console.log(err);
          reject({ msg: err });
        });
    });
  }

  postFile(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders()
      .append('Authorization', `Bearer ${this.token}`);

      return this.http.post(`${environment.API_URL}${url}`, params, {params: params, headers: headers})
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ data: response.response.data, msg: 'success' });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          reject({ msg: err.error.error });
        });
    });
  }
  post2(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${environment.API_URL}${url}`, JSON.stringify(params))
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ data: response.response, msg: 'success' });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          reject({ msg: err.error.error });
        });
    });
  }

  put(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${environment.API_URL}${url}`, JSON.stringify(params))
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ data: response.response.data, msg: 'success' });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          reject({ msg: err.error.error });
        });
    });
  }

  put2(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${environment.API_URL}${url}`, JSON.stringify(params))
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ data: response.response, msg: 'success' });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          reject({ msg: err.error.error });
        });
    });
  }

  postWithoutToken(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${environment.API_URL}${url}`, JSON.stringify(params))
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ data: response.data, msg: response.msg });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          reject({ msg: err.error.error });
        });
    });
  }

  loginPostWithoutToken(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${environment.API_URL}${url}`, JSON.stringify(params))
        .subscribe((response: any) => {
          if (response.state == 'success') {
            resolve({ access_token: response.access_token });
          }
          else {
            reject({ msg: response.msg });
          }
        }, (err): any => {
          reject({ msg: err.error.error });
        });
    });
  }
}
