import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';
import { IPromotion } from 'app/models/promotion';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor(
    private httpService : HttpService
  ) { }

  getListPage(page: number = 1, search:any  = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));

    return this.httpService.get(`admin/promotions/list`,  params)
  }

  add(promotion: IPromotion) : Promise<any> {
    return this.httpService.post(`admin/promotions`, promotion);
  }
  saveProducts(sku: string,products:any): Promise<any> {
    return this.httpService.put(`admin/promotions/${sku}/addProducts`, products);
  }
  getPromotion(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/promotions/${sku}/detail`);
  }

}
